// ***********************************************//
// colors
// ***********************************************//

$lightblue: #69b9ed;
$sun: #f1f16e;
$white: #ffffff;
$softWhite: #dad6d6;
$black: #000000;
$dark: #1e1e1e;

// light theme
$primaryLight: #781ad6;
$secondaryLight: #af6aff;
$tertiaryLight: #c4a8dd;
$quaternaryLight: #f5edff;
$fiveLight: #490b86;
$sixLight: #e4c5fe;

// dark theme
$primaryDark: #363957;
$secondaryDark: #a1d6b7;
$tertiaryDark: #f2fdf5;
$quaternaryDark: #7cc994;
$fiveDark: #62c982;
$fiveDarker: #2d6042;
$accessibleDark: #148237;

// ***********************************************//
// fonts and fonts weights
// ***********************************************//

$montserrat: "Montserrat", sans-serif;
$sourceCode: "Source Code Pro", monospace;
$commissioner: "Commissioner", sans-serif;
$fontAwesome: "FontAwesome";
$didot: "Didot", sans-serif;

$ultralight: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

// ***********************************************//
// transitions
// ***********************************************//

$lightning: 200ms ease-in-out;
$quick: 300ms ease-in-out;
$slow: 500ms ease-in-out;

// ***********************************************//
// media queries / breakpoints
// ***********************************************//

$xxxsDown: 400px; // max-width: 400px
$xxxsUp: 401px; // min-width: 401px
$xxsDown: 500px; // max-width: 500px
$xxsUp: 501px; // min-width: 501px
$xsDown: 567px; // max-width: 567px
$xsUp: 568px; // min-width: 567px
$smDown: 767px; // max-width: 767px
$smUp: 768px; // min-width: 768px
$medDown: 991px; // max-width: 991px
$medUp: 992px; // min-width: 992px
$large: 1000px; // max or min -width: 1100px
$xlarge: 1200px; // max or min -width: 1300px
$xxlarge: 1440px; // max or min -width: 1440px

// ***********************************************//
// svg icons
// ***********************************************//

$sandbox: url("../../assets/images/codeSandbox.svg");
$linkedin: url("../../assets/images/linkedin.svg");
$codePen: url("../../assets/images/codepen.svg");
$github: url("../../assets/images/github.svg");
$instagram: url("../../assets/images/instagram.svg");
$spotify: url("../../assets/images/spotify.svg");

//****************************************************************************/
//	Images
//****************************************************************************/
$svgPath: "../../assets/images/";
$publicPath: "../../../public/images/";

.fade-in {
    opacity: 1;
    transition: opacity $quick;
}

.fade-out {
    opacity: 0;
    transition: opacity $quick;
}

.fade-in-up {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity $quick, transform $quick;
}

.fade-out-down {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity $quick, transform $quick;
}

.slide-in-left {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity $slow, transform $slow;
}

.slide-out-left {
    opacity: 0;
    transform: translateX(-35px);
    transition: opacity $slow, transform $slow;
}

.twirl-in {
    animation: twirlIn .5s ease-in-out;
}

.twirl-out {
    animation: twirlOut .5s ease-in-out;
}

.delay-half {
    transition-delay: .5s;
}

.delay-1 {
    transition-delay: 1s;
}

@keyframes twirlIn {
    from {
        opacity: 0;
        transform: scale(1.2) rotate(0deg);
    }
    to {
        opacity: 1;
        transform: scale(1) rotate(-360deg);
    }
}

@keyframes twirlOut {
    from {
        opacity: 1;
        transform: scale(1) rotate(-360deg);
    }
    to {
        opacity: 0;
        transform: scale(1.2) rotate(0deg);
    }
}

// cascade animation loop
$nums: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);

@each $num in $nums{
    .fade-in-up:nth-of-type(#{$num}) {
        -webkit-transition-delay: $num * 0.2s;
        transition-delay: $num * 0.2s - 0.2s;
    }
    .fade-out-down:nth-of-type(#{$num}) {
        -webkit-transition-delay: $num * 0.2s;
        transition-delay: $num * 0.2s - 0.2s;
    }
}

@keyframes fadeInRight {
    0% {opacity: 0; transform: translateX(20px);}
    5% {opacity: 0; transform: translateX(20px);}
    100% {opacity: 1; transform: translateX(0);}
}

@keyframes fadeInLeft {
    0% {opacity: 0; transform: translateX(-20px);}
    100% {opacity: 1; transform: translateX(0);}
}
.testimonials-container {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	height: 100%;
	max-width: 1200px;
	padding: 18vh 4em 0 4em;
	margin: 0 auto;

	@media screen and (min-width: $smUp) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
	}

	&.animate {
		&::before {
			transform: translate(-30%, -40vw) scale(-1, 1) rotate(0);

			@media screen and (min-width: 375px) {
				transform: translate(-40%, -28vw) scale(-1, 1) rotate(0);
			}

			@media screen and (min-width: $xsUp) {
				transform: translate(-40%, -13vh) scale(-1, 1) rotate(0);
			}

			@media screen and (min-width: $smUp) {
				transform: translate(65%, -11vh) scale(1, 1) rotate(0);
			}
		}
	}

	&::before {
		@include pseudo;
		@include mask-svg-set;
		@include mask-svg-img("../../assets/images/quote.svg");
		background-color: $primaryLight;
		width: 30%;
		height: 100%;
		right: 0;
		left: auto;
		top: 0;
		transform: translate(-40%, 30vh) scale(-1, 1) rotate(-30deg);
		transition: transform $quick;

		@media screen and (min-width: $smUp) {
			right: auto;
			left: 0;
			top: auto;
			width: 25%;
			max-width: none;
			transform: translate(65%, -12vh) scale(1, 1) rotate(-30deg);
		}

		.theme-dark & {
			background-color: $fiveDarker;
		}
	}

	.inner-wrap {
		grid-column: 2;
		grid-row: 3;

		@media screen and (min-width: $smUp) {
			grid-row: 1;
		}
	}

	@media screen and (max-width: 350px) {
		.section-title {
			font-size: 1.25em;
		}
	}

	.testimonial {
		display: flex;
		flex-direction: column;
		justify-content: center;
		visibility: hidden;
		opacity: 0;
		height: 0;
		width: 0;
		transform: translateX(-9px);
		transition: opacity $quick, transform $quick;

		@media screen and (min-width: $smUp) {
			max-width: 380px;
		}

		.person {
			opacity: 0;
			margin-top: 2em;
			text-align: right;
			transform: translateX(12px);
			transition: opacity $quick, transform $quick;
			transition-delay: 0.3s;
		}

		&.show {
			visibility: visible;
			opacity: 1;
			height: auto;
			width: 100%;
			min-height: 250px;
			transform: translateX(0);

			.person {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	.btns-container {
		margin-top: 2em;
	}

	.testimonial-btn {
		border: 2px solid $primaryLight;
		border-radius: 90px;
		width: 0.5em;
		height: 0.5em;
		padding: 5px;
		margin-left: 20px;

		.theme-dark & {
			border: 2px solid $quaternaryDark;
		}

		&:nth-of-type(1) {
			margin: 0px;
		}

		&.active,
		&:hover,
		&:focus {
			background-color: $primaryLight;

			.theme-dark & {
				background-color: $quaternaryDark;
			}
		}
	}
}

.themeBtn {
    position: fixed;
    right: 0;
    top: 0;
    width: 27px;
    height: 27px;
    border: none;
    border-radius: 0 0 1em 1em;
    z-index: 10;
    transform: translate(-8vw, 0);

    @media screen and (min-width: $smUp) {
        transform: translate(-4vw, 0);
    }

    &::before {
        @include pseudo;
        top: 0;
        left: 0;
        border-radius: 50%;
        transition: box-shadow $quick;
    }

    &.dark {
        margin: 0;
        padding: 0;
        background-color: $black;
        
        &::before {
            height: 15px;
            width: 15px;
            box-shadow: -3px 2px 0 1px $white;
            transform: translate(67%, 13%);
        }
    }

    &.light {
        background-color: $lightblue;
        box-shadow: 1px 4px 8px rgba($dark, 0.4);

        &::before {
            width: 18px;
            height: 18px;
            box-shadow: inset 17px -20px 0 0px $sun;
            transform: translate(26%, 15%);
        }  
    }
}
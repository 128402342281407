@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@200&family=Martel+Sans:wght@200;300;400;600;700&family=Source+Code+Pro:wght@200;300;400&family=Spartan:wght@100;200;300;400&display=swap');

@font-face {
  font-family: 'Menlo';
  src: url('http://fonts.cdnfonts.com/css/menlo') format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Didot"; 
  src: url("//db.onlinewebfonts.com/t/251039e6849ad977a8bfc40b564dce89.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/251039e6849ad977a8bfc40b564dce89.woff2") format("woff2"), 
  url("//db.onlinewebfonts.com/t/251039e6849ad977a8bfc40b564dce89.woff") format("woff"), 
  url("//db.onlinewebfonts.com/t/251039e6849ad977a8bfc40b564dce89.ttf") format("truetype");
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400&display=swap');
body {
	overflow-x: hidden;

	&.theme-dark,
	&.theme-light {
		// position: relative; this fucks up the focus trap background color

		&::after {
			@include pseudo;
			z-index: 4;
		}
	}

	&.theme-dark::after {
		background-color: rgba($primaryDark, 0.8);
	}

	&.theme-light::after {
		background-color: rgba($primaryLight, 0.8);
	}
}

p {
	font-family: $montserrat;
	font-size: 0.875em;
	line-height: 1.65;

	@media screen and (min-width: $medUp) {
		font-size: 1em;
	}
}

.section-title {
	font-size: 1.5em;
	font-family: $montserrat;
	color: $black;

	&:not(.i-am) {
		position: relative;
		margin-bottom: 2em;

		&::after {
			@include pseudo;
			width: 1em;
			height: 3px;
			border-radius: 90px;
			background-color: $primaryLight;
			transform: translate(0, 1.8em);

			.theme-dark & {
				background-color: $fiveDark;
			}
		}
	}

	@media screen and (min-width: $xxsUp) {
		font-size: 1.875em;
	}

	@media screen and (min-width: $smUp) {
		font-size: 2.125em;
	}

	@media screen and (min-width: $xlarge) {
		font-size: 2.5em;
	}
}

.icon {
	position: relative;

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		width: 1.5em;
		height: 1.5em;
		transition: all $quick;
		transform: translate(-50%, -50%);
	}

	&:hover,
	&:focus {
		&::before,
		&::after {
			transform: translate(-50%, -50%) scale(0.8);
			opacity: 0.8;
		}
	}

	&.sandbox::before {
		content: $sandbox;
	}
	&.linkedin::before {
		content: $linkedin;
	}
	&.codepen::before {
		content: $codePen;
	}
	&.linkedin::before {
		content: $linkedin;
	}
	&.github::before {
		content: $github;
	}
	&.instagram::after {
		content: $instagram;
	}
	&.spotify::before {
		content: $spotify;
	}
}

.btn-scroll {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 2em;
	width: 2em;
	color: $white;
	border: 1px solid $white;
	border-radius: 90px;
	font-family: $sourceCode;
	font-size: 1em;
	z-index: 1;
	transform: translate(22vw, -5vw);
	transform-origin: center;
	transition: transform $quick, border-color $quick;

	&::before {
		@include pseudo;
		@include mask-svg-set;
		@include mask-svg-img("../../assets/images/chevron.svg");
		left: 50%;
		top: 50%;
		background-color: $white;
		width: 1em;
		height: 1em;
		transform: translate(-50%, -50%);
		transition: background-color $quick, width $quick, height $quick;
	}

	&:hover {
		border-color: $sixLight;

		.theme-dark & {
			border-color: $fiveDark;
		}

		&::before {
			height: 0.8em;
			width: 0.8em;
			background-color: $sixLight;

			.theme-dark & {
				background-color: $fiveDark;
			}
		}
	}
}

.pager-btns-container {
	position: relative;
	display: flex;
	z-index: 1;
}

.pager-btn {
	background-color: rgba($fiveLight, 0.5);
	border: none;
	border-radius: 90px;
	width: 0.35em;
	height: 0.35em;
	padding: 0.35em;

	&:nth-of-type(2) {
		margin: 0 15px;
	}

	&:nth-of-type(4) {
		margin-left: 15px;
	}

	@media screen and (min-width: $medUp) {
		width: 1em;
		height: 1em;
		padding: 0.5em;
	}

	.theme-dark & {
		background-color: rgba($primaryDark, 0.5);
	}

	&.active,
	&:hover,
	&:focus {
		background-color: $fiveLight;

		.theme-dark & {
			background-color: $primaryDark;
		}
	}
}

#save-code-popup-parent {
	display: none;
	height: 0;
	width: 0;
}

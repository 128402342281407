.page-wrapper {
    position: relative;
    height: 100vh;
    background-color: $white;

    .inside-wrapper {
        position: relative;
    }

    #maincontent {
        position: relative;
        
        &::before {
            @include pseudo;
            bottom: auto;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 0;
            padding-top: 40%;
            z-index: 0;
            transform: translate(0, -50%);
        } 
    }

    &.theme-light {
        main::before {
            background-image: url('../../assets/images/hero-waves-light.svg');
        }
    }
    &.theme-dark {
        main::before {
            background-image: url('../../assets/images/hero-waves-dark.svg');
        }
    }

    .section-wrapper {
        position: relative;
        margin: 0 auto;
    }
}
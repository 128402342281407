.fullPageMenu {
	position: fixed;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	transition: width $quick, transform $quick, visibility $quick, opacity $quick;

	&.appear {
		width: 100vw;
		overflow: hidden;
		visibility: visible;
		opacity: 1;
		scroll-behavior: none;
	}
}

.nav-wrapper {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 12% 1fr 1.5fr;
	align-items: flex-end;
	padding: 3em 0;
	height: 90vh;
	z-index: 2;
	transition: height $quick, width $quick, grid-template-columns $quick,
		grid-template-rows $quick;

	@media screen and (max-width: $medDown) {
		grid-template-columns: 9% 1fr 1.5fr;
	}

	@media screen and (max-width: $smDown) {
		grid-template-columns: 20% 1fr;
		grid-template-rows: 1fr 0.25fr 0.1fr;
	}

	@media screen and (max-width: $xxsDown) {
		padding: 5em 0 4em 0;
		height: 82vh;
	}

	.social {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column: 2;
		grid-row: 2;
		grid-gap: 1em;
		margin-bottom: 4em;
		max-width: 550px;
		transition: all $quick;

		@media screen and (max-width: $smDown) {
			grid-template-columns: 1fr;
			grid-column: 1;
			grid-gap: 4em;
			justify-self: center;
		}

		@media screen and (max-width: $xxsDown) {
			grid-gap: 3em;
		}
	}

	.icon-list-item {
		opacity: 0;
		transition: opacity $quick;

		.appear & {
			opacity: 1;
			@include cascadeTransition;
		}
	}

	.copyright {
		grid-column: span 2;
		grid-row: 3;
		padding-left: 3em;
		max-width: 123px;
		font-family: $commissioner;
		font-size: 0.75em;
		color: $sixLight;
		letter-spacing: 0.05em;
		line-height: 1.4;
		transition: font-size $quick;

		.theme-dark & {
			color: $secondaryDark;
		}

		@media screen and (min-width: $smUp) {
			grid-column: 2;
			padding-left: 0;
			max-width: none;
			font-size: 0.875em;
			opacity: 1;
		}
	}

	nav {
		display: grid;
		justify-content: center;
		grid-column: 3;
		grid-row: span 2;
		grid-gap: 0.25em;
		align-self: center;
		font-size: 4em;
		text-align: right;
		padding-top: 5vh;
		padding-right: 1em;
		transition: font-size $quick, grid-column $quick, grid-gap $quick,
			grid-row $quick;

		@media screen and (max-width: $smDown) {
			grid-column: 2;
			grid-gap: 0.5em;
			grid-row: 1;
			font-size: 3em;
			padding-right: 15vw;
			padding-top: 0vh;
		}

		@media screen and (max-width: $xxsDown) {
			font-size: 2em;
		}

		&::before {
			@include pseudo;
			width: 100%;
			height: 100vh;
			background-color: $primaryLight;
			z-index: -1;
			transform: scaleY(1.5);
			transition: transform $slow;
		}

		&::after {
			@include pseudo;
			left: auto;
			right: 0;
			width: 54%;
			height: 100vh;
			background-color: $secondaryLight;
			border-top-left-radius: 15% 43%;
			border-bottom-left-radius: 94% 113%;
			z-index: -1;
			transform: scale(1.3);
			transition: width $quick;

			@media screen and (max-width: $smDown) {
				width: 70%;
			}
		}

		.theme-dark & {
			&::before {
				background-color: $primaryDark;
			}
			&::after {
				background-color: $quaternaryDark;
			}
		}
	}

	.nav-item {
		padding-bottom: 0.5em;
		opacity: 0;
		font-family: $montserrat;
		font-weight: $semibold;
		text-transform: lowercase;
		letter-spacing: 0.04em;
		transition: all $slow;
		transition-duration: 1000ms;
		transform: translateY(4vw);

		&.open {
			@include cascadeTransition;
			opacity: 1;
			transform: translateY(0);
		}
	}

	.nav-link {
		position: relative;
		text-decoration: none;
		color: $white;
		transition: color $quick;

		&::after {
			@include pseudo;
			top: 50%;
			bottom: auto;
			width: 0;
			height: 10px;
			background-color: rgba($white, 0.4);
			opacity: 0;
			transform: translate(-13px, 0);
			transition: opacity $quick, width $quick;
		}

		&:hover,
		&:focus,
		&.active {
			&::after {
				opacity: 1;
				width: calc(100% + 30px);
			}
		}
	}
}

.about-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.5fr 1fr 1fr 0.5fr;
	align-items: center;
	max-width: 1200px;
	padding: 0 4em 0 4em;
	margin: 0 auto;

	@media screen and (min-width: $xxsUp) {
		padding: 0 4em;
		margin-top: 0;
	}

	@media screen and (min-width: $smUp) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		grid-gap: 4em;
		padding: 0 3em;
		height: 100vh;
	}

	p:last-of-type {
		margin-top: 1.5em;
	}

	.col {
		&.text {
			grid-row: 3;

			@media screen and (min-width: $smUp) {
				grid-row: 1;
				padding-left: 4em;
			}
		}

		&.right {
			position: relative;
			grid-row: 2;

			@media screen and (min-width: $smUp) {
				grid-row: 1;
			}

			&::after {
				@include pseudo;
				@include mask-svg-set;
				@include mask-svg-img("../../assets/images/about-shape.svg");
				background-color: $primaryLight;
				padding-bottom: 85%;
				width: 85%;
				max-width: 375px;
				left: auto;
				bottom: auto;
				transform: translate(8%, -20%);

				@media screen and (min-width: $smUp) {
					width: 100%;
					max-width: 500px;
					transform: translate(-10%, -33%);
				}

				.theme-dark & {
					background-color: $primaryDark;
				}
			}
		}
	}

	.image-container {
		position: relative;
		width: 45%;
		max-width: 200px;
		margin: 13% 20% 0 auto;
		z-index: 1;

		@media screen and (min-width: $smUp) {
			width: 50%;
			margin: 5% 50% 0 auto;
		}

		&::after {
			opacity: 0;
			@include pseudo;
			@include mask-svg-set;
			@include mask-svg-img("../../assets/images/about-crosses.svg");
			background-color: $sixLight;
			padding-bottom: 40%;
			width: 100%;
			left: auto;
			top: auto;
			transform: translate(5em, 3em) scale(0);
			transition-delay: 3s;
			transition: opacity $slow, transform $slow;

			.theme-dark & {
				background-color: $quaternaryDark;
			}
		}

		&.animate {
			&::after {
				opacity: 1;
				transform: translate(5em, 3em) scale(0.7);
			}
		}

		.img-wrapper {
			@include aspectRatioContainer(350px, 355px);
		}

		.ft-img {
			@include aspectRatioImage;
		}
	}
}

.project-modal {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 100vh;
	filter: drop-shadow(-1px 9px 5px rgb(0 0 0 / 0.1));
	transform: translate(-50%, -50%);
	transition: background-image 0.01s ease-in-out;
	z-index: 5;

	@media screen and (max-width: $xsDown) {
		background-color: $white;
		background-image: none;
	}

	@media screen and (min-width: $xsUp) {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1077.557' height='1594.956' viewBox='0 0 1077.557 1594.956'%3E%3Cpath id='Path_231' data-name='Path 231' d='M7588.16-1342.812c109.731,41.615,185.293,76.909,236.613,57.659s113.417-72.535,184.977-53.482,86.49,111.222,68.966,315.365,121.5,478.118,102.153,645.972c-9.453,171.691-27.156,355.635-150.972,467.37-73.394,53.713-317.811,136.6-368.543,44.714-68.469-98.3-88.983-117.267-166.66-127.462C7263,19.3,7197.532-193.126,7211.323-355.318c7.143-84.015,135.085-189.952,145.832-393.9,10.037-190.484-83.984-366.2-69.1-405.317C7349.84-1316.857,7478.431-1384.427,7588.16-1342.812Z' transform='matrix(0.998, -0.07, 0.07, 0.998, -7097.508, 1922.308)' fill='%23fff'/%3E%3C/svg%3E%0A");
	}

	@media screen and (min-width: $smUp) {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='873.342' height='894.699' viewBox='0 0 873.342 894.699'%3E%3Cpath id='Path_231' data-name='Path 231' d='M7588.16-1354.031c109.731,22.433,185.293,41.459,236.613,31.082s116.814-47.988,159.252-43.169,112.215,74.294,94.691,184.34-37.761,233.771-57.105,324.255c-9.453,92.552-17.865,241.752-141.681,301.984-73.394,28.955-167.844,47.556-218.576-1.974-68.469-52.991-88.983-63.214-166.66-68.71-231.7,6.459-239.589-61.771-225.8-149.2s32.4-449.047,63.285-492.8C7393.962-1355.724,7478.431-1376.464,7588.16-1354.031Z' transform='matrix(0.998, -0.07, 0.07, 0.998, -7151.71, 1926.949)' fill='%23fff'/%3E%3C/svg%3E%0A");
	}

	@media screen and (min-width: $medUp) {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1628.992' height='1352.458' viewBox='0 0 1628.992 1352.458'%3E%3Cpath id='Path_231' data-name='Path 231' d='M7985.078-1323.472c136.978,68.5,199.745,157.677,312.771,138.967s211.534-142.459,297.545-154.017,199.337,22.652,239.755,154.181S8730.1-927.628,8683.194-788.775s29.791,241.182,44.149,315.225,17.378,372.41-316.152,328.739c-140.737-24.106-154.79-128.639-266.522-217.943-150.793-95.542-341.779-61.858-466.056,29.146-204.738,149.925-293.277,32.506-346.261-24.213s-56.862-297.519,53.906-430.751-62.1-336.72,73.96-494.485S7848.1-1391.969,7985.078-1323.472Z' transform='matrix(0.998, -0.07, 0.07, 0.998, -7181.83, 2001.558)' fill='%23fff'/%3E%3C/svg%3E%0A");
		max-width: 1200px;
	}

	.inner-wrapper {
		position: relative;
	}

	.close-btn {
		@include mask-svg-set;
		@include mask-svg-img("../../assets/images/x.svg");
		position: absolute;
		left: 2em;
		top: 7vh;
		width: 3em;
		height: 3em;
		background-color: $white;
		transition: background-color $quick;

		@media screen and (max-width: $xsDown) {
			background-color: $black;
		}

		&:hover,
		&:focus {
			background-color: $fiveLight;

			.theme-dark & {
				background-color: $fiveDark;
			}
		}
	}

	.dialog-content {
		display: flex;
		place-items: center;
		position: relative;
		height: 72vh;
		width: 100%;
		max-width: 85%;
		margin: 18vh auto;

		@media screen and (min-width: $xsUp) {
			max-width: 300px;
			height: 60vh;
		}
		@media screen and (min-width: $smUp) {
			max-width: 64%;
		}
		@media screen and (min-width: $medUp) {
			max-width: 700px;
			height: 45vh;
			margin: 24vh auto;
		}

		.content-wrapper {
			width: 100%;
		}

		.col {
			&:first-of-type {
				animation: fadeInLeft 0.5s ease-in-out;

				@media screen and (min-width: $medUp) {
					width: 68%;
				}
			}
			&:last-of-type {
				display: flex;
				flex-wrap: wrap;
				gap: 3em;
				width: 100%;
				justify-content: space-between;
				animation: fadeInRight 0.5s ease-in-out;

				@media screen and (max-width: $smDown) {
					flex-direction: column;
				}

				@media screen and (min-width: $smUp) {
					align-items: self-end;
				}

				@media screen and (min-width: $medUp) {
					flex-direction: column;
					align-items: center;
					text-align: center;
					width: 23%;

					p {
						margin-bottom: 0.5em;
					}
				}
			}
		}

		h2 {
			font-size: 1.5em;
			font-weight: $semibold;
			margin-bottom: 1.5em;
			margin-right: auto;

			@media screen and (max-width: $smDown) {
				margin-bottom: 0.75em;

				&::after {
					display: none;
				}
			}
		}

		.desc {
			font-size: 0.875em;
			margin-bottom: 2em;

			@media screen and (max-width: $smDown) {
				overflow: auto;
				max-height: 180px;
				position: relative;
			}

			@media screen and (min-width: $medUp) {
				margin-bottom: 3em;
			}
		}

		.roles {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 10px;
			margin-bottom: 2em;

			@media screen and (min-width: $medUp) {
				margin-bottom: 0;
				gap: 10px 0px;
			}

			p {
				@media screen and (min-width: $medUp) {
					font-size: 0.875em;
				}
			}
		}

		span {
			white-space: nowrap;
			font-weight: $semibold;
			margin-right: 0.5em;
		}

		.row {
			display: flex;

			@media screen and (min-width: $medUp) {
				flex-wrap: nowrap;
				justify-content: space-between;
			}

			&.website {
				flex-wrap: wrap;

				.roles {
					p {
						@media screen and (min-width: $medUp) {
							width: 50%;
						}
					}
				}
			}

			&.art,
			&.design {
				align-items: center;
				gap: 3em;
				margin-bottom: -40px;

				@media screen and (max-width: $smDown) {
					flex-direction: column;
					flex-wrap: wrap;
					margin-bottom: 0;
					gap: 1em;
				}

				.desc {
					margin-bottom: 1em;
				}

				.col {
					width: 100%;

					@media screen and (min-width: $medUp) {
						&:first-of-type {
							width: 40%;
							margin-top: -35px;
						}

						&:last-of-type {
							width: 60%;
						}
					}
				}
			}
		}

		.tools {
			p {
				margin-bottom: 1em;
			}

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 20px;

				li {
					position: relative;

					&:hover {
						.alt-tool {
							font-family: $montserrat;
							display: block;
							max-width: 100px;
							text-wrap: auto;
							text-align: center;
							text-transform: capitalize;
						}
					}
				}

				@media screen and (min-width: $medUp) {
					flex-wrap: wrap;
					gap: 25px 4%;

					li {
						width: 48%;
						display: flex;
						place-content: flex-end;
						place-items: center;
						justify-content: center;
						align-items: center;
					}
				}

				img {
					width: 25px;
					margin: auto;

					@media screen and (min-width: $medUp) {
						width: 30px;
					}
				}

				.alt-tool {
					display: none;
					position: absolute;
					background: $white;
					z-index: 1;
					padding: 1em;
					font-size: 0.875em;
					font-weight: 300;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			&.design {
				display: flex;
				margin-top: 2em;
				align-items: flex-start;

				@media screen and (min-width: $smUp) {
					flex-direction: column;
					align-items: baseline;
				}

				ul {
					flex-wrap: nowrap;
					gap: 25px 20px;
				}
			}
		}

		.thumbnails {
			position: relative;
			overflow: hidden;
			max-width: 300px;
			margin: 0 auto;

			@media screen and (min-width: $smUp) {
				max-width: none;
			}

			.thumbnail-slide {
				opacity: 0;
				visibility: hidden;
				height: 0;
				z-index: -100;
				transition: opacity $quick;

				&.active {
					opacity: 1;
					visibility: visible;
					height: 100%;
					z-index: 1;
					transition: opacity $quick;
				}

				img {
					border-radius: 15px;
				}
			}

			&::before {
				@include pseudo;
				right: auto;
				left: -2em;
				background-color: $white;
				width: 2em;
				height: 100%;
			}

			&:after {
				@include pseudo;
				left: auto;
				right: -2em;
				background-color: $white;
				width: 2em;
				height: 100%;
			}
		}

		.pager-btns-container {
			margin: 0 auto;
			margin-top: -20px;

			.pager-btn {
				&:not(:first-of-type),
				&:not(:last-of-type) {
					margin: 0 10px;
				}

				@media screen and (max-width: $medDown) {
					width: 1em;
					height: 1em;
				}
			}
		}

		.button-col {
			max-width: 135px;

			@media screen and (min-width: $smUp) {
				display: flex;
				max-width: none;
			}
		}

		.button-wrapper {
			.theme-dark & {
				border-color: $fiveDark;
			}

			@media screen and (min-width: $medUp) {
				width: 100%;
			}

			a {
				display: block;
				position: relative;
				cursor: pointer;
				pointer-events: all;
				color: $black;
				font-family: $montserrat;
				z-index: 1;
				border: 2px solid $secondaryLight;
				box-shadow: 2px 2px 6px 0px rgba($black, 20%);
				background: $white;
				border-radius: 1em;
				padding: 0.375em 1.25em;
				text-align: left;
				letter-spacing: 1px;
				font-size: 0.9em;

				.theme-dark & {
					border: 2px solid $fiveDark;
				}

				div {
					position: relative;
					padding-right: 1em;

					&::after {
						@include pseudo;
						@include mask-svg-set;
						@include mask-svg-img("../../assets/images/arrow.svg");
						left: auto;
						background-color: $secondaryLight;
						width: 1em;
						transform: translateX(0.5em);
						transition: width $quick;

						.theme-dark & {
							background-color: $fiveDarker;
						}
					}
				}

				&:hover,
				&:focus {
					background-color: $fiveLight;
					color: $white;

					.theme-dark & {
						background-color: $fiveDarker;
					}

					div {
						&::after {
							background-color: $white;
						}
					}
				}
			}
		}
	}
}

.gallery-container {
	margin: 0 auto;
	width: 85%;

	@media screen and (min-width: $xsUp) {
		width: 70%;
	}

	@media screen and (min-width: $medUp) {
		max-width: 1000px;
	}

	.filter-bar {
		grid-row: 2;
		display: grid;
		padding-bottom: 2em;
		align-items: center;
		align-content: center;

		.tabs-wrapper {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			justify-items: flex-start;

			@media screen and (min-width: $smUp) {
				grid-template-columns: repeat(4, 1fr) 60%;
			}
		}

		.tab {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0.5em 1.5em;
			border: none;
			font-family: $sourceCode;
			font-weight: $regular;
			font-size: 1em;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			color: $black;

			@media screen and (max-width: $medDown) {
				padding: 0.5em 1em;
				font-size: 0.875em;
			}

			&.active,
			&:hover,
			&:focus {
				background-color: $fiveLight;
				color: $white;
				transition: background-color $quick, color $quick;

				.theme-dark & {
					background-color: $accessibleDark;
				}
			}
		}
	}

	.section-title {
		grid-row: 1;
		margin-bottom: 0;
		justify-self: flex-end;
	}

	.project-grid {
		grid-row: 3;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		text-align: center;

		@media screen and (min-width: $smUp) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: $medUp) {
			grid-template-columns: repeat(3, 1fr);
		}

		&.no-pointer {
			pointer-events: none;
		}
	}

	.project-btn {
		-webkit-appearance: none;
		appearance: none;
	}

	.project-item {
		position: relative;
		height: 40vw;
		width: 100%;
		overflow: hidden;

		@media screen and (min-width: $xsUp) {
			height: 32vw;
		}

		@media screen and (min-width: $smUp) {
			height: 22vw;
		}

		@media screen and (min-width: $xlarge) {
			max-height: 320px;
		}
	}

	.project-image {
		position: absolute;
		left: 0;
		top: 0;
		border: none;
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		transform-origin: center;
		transition: all $quick, transform $quick;

		&::after {
			@include pseudo;
			color: transparent;
			padding-top: 30px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='243.015' height='118.161' viewBox='0 0 243.015 118.161'%3E%3Cpath id='Path_254' data-name='Path 254' d='M7782.194-833.63s45.345,2.689,55.682,17.465-10.685,75.123,21.728,91.942,76.451,9.825,107.923-24.666,57.682-25.205,57.682-25.205V-833.63Z' transform='translate(-7782.194 833.63)' fill='rgba(255,255,255,0.82)'/%3E%3C/svg%3E%0A");
			background-size: 0;
			background-position: right top;
			background-repeat: no-repeat;
			transition: background-size $lightning, color $quick;
		}

		&:hover,
		&:focus {
			transform: scale(1.2, 1.2);

			&::after {
				content: "+";
				color: $primaryLight;
				font-family: $montserrat;
				font-weight: $semibold;
				font-size: 3em;
				background-size: 100% auto;

				@media screen and (min-width: $xlarge) {
					font-size: 5em;
				}

				.theme-dark & {
					color: $primaryDark;
				}
			}
		}
	}
}

.scroll-tracker {
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100vw;
    padding: 0.1em;
    z-index: 3;
    transition: background $slow;

    &.scrolled {
        background: linear-gradient(to right, rgba($tertiaryLight, 0.75), calc(var(--scroll, 0) * 100%), transparent 0);

        .theme-dark &{
            background: linear-gradient(to right, rgba($quaternaryDark, 0.75), calc(var(--scroll, 0) * 100%), transparent 0);
        }
    }
}

::-webkit-scrollbar {
    width: 0; 
    background: transparent;  
    scrollbar-width: none;
    -ms-overflow-style: none;
}

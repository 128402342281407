.page-wrapper.home {
	width: 100vw;
	overflow-x: hidden; // need this to remove white at bottom

	.hero-container,
	#maincontent > * {
		width: 100vw;

		@media screen and (min-width: $smUp) {
			height: 100vh;
		}
	}

	.section-wrapper {
		&:nth-of-type(1) {
			display: grid;
			grid-template-rows: 20% 70% 10%;

			@media screen and (min-width: $medUp) {
				grid-template-rows: 35% 65%;
			}
		}
		&:nth-of-type(1),
		&:nth-of-type(2) {
			padding: 5em 0;

			@media screen and (min-width: $medUp) {
				padding: 0;
			}
		}
		&:nth-of-type(3) {
			background-color: $white;
		}
		&:last-of-type {
			height: auto !important;
			background: $white;
		}
		&:not(:nth-of-type(2)) {
			overflow: hidden;
		}
	}
}
